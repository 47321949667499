<template>
  <div>
    <v-row justify="space-between">
      <!-- Header -->
      <v-col
        cols="12"
        sm="6"
        md="auto"
      >
        <h3 class="mb-10">
          Manage Loan
        </h3>
      </v-col>
      <!-- Select Project -->
      <v-col
        cols="12"
        sm="6"
        md="auto"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              v-bind="attrs"
              class="text-capitalize select-project"
              :block="$vuetify.breakpoint.xs"
              v-on="on"
            >
              <v-img
                v-if="project && project.logo"
                :src="project.logo.uploadURL"
                width="48"
                height="48"
                contain
                class="logo-image"
              />
              {{ project && project.name ? project.name : 'Select project' }}
              <v-icon
                right
                size="24"
              >
                {{ icons.mdiChevronDown }}
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in projects"
              :key="item.ID"
              link
            >
              <v-list-item-title
                @click="$store.commit('manageSalary/setCurrentProject', item)"
                v-text="item.name"
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-alert
      v-if="messageAlert !== null"
      dismissible
      type="error"
      @input="messageAlert = null"
    >
      {{ messageAlert }}
    </v-alert>
    <!-- Tabs -->
    <v-tabs class="mb-10">
      <v-tab
        class="text-capitalize"
        @click="() => {
          loan = 'Submission'
        }"
      >
        Loan Submission
      </v-tab>
      <v-tab
        class="text-capitalize"
        @click="() => {
          loan = 'Type'
        }"
      >
        Loan Type
      </v-tab>
    </v-tabs>
    <!-- Toolbar -->
    <v-row class="mb-6">
      <v-col
        v-if="loan === 'Type'"
        cols="12"
        sm="6"
        md="auto"
      >
        <v-btn
          v-if="loan === 'Type'"
          color="primary"
          class="text-capitalize"
          :block="$vuetify.breakpoint.smAndDown"
          @click="openFormLoan('type')"
        >
          <v-icon
            left
            color="white"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Create loan type
        </v-btn>
        <div v-if="project && project.ID">
          <FormLoanType
            v-model="showCreateLoanTypeModal"
            :project="project"
          />
        </div>
      </v-col>

      <v-col
        v-if="loan === 'Submission'"
        cols="12"
        sm="6"
        md="auto"
      >
        <v-btn
          v-if="loan === 'Submission'"
          color="primary"
          class="text-capitalize"
          :block="$vuetify.breakpoint.smAndDown"
          @click="openFormLoan('sub')"
        >
          <v-icon
            left
            color="white"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Create loan submission
        </v-btn>
      </v-col>
      <div v-if="project && project.ID">
        <FormLoanSub
          v-model="showCreateLoanSubModal"
        />
      </div>

      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-if="loan=== 'Type'"
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search loan type"
          single-line
          hide-details
          dense
          outlined
        >
        </v-text-field>
        <v-text-field
          v-if="loan=== 'Submission'"
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search loan submission"
          single-line
          hide-details
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <ListLoanType
      v-if="loan === 'Type'"
      :search="search"
    />
    <ListLoanSub
      v-if="loan === 'Submission'"
      :search="search"
    />
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import { mdiPlus, mdiMagnify, mdiChevronDown } from '@mdi/js'
import ListLoanType from './components/ListLoanType.vue'
import FormLoanType from './components/FormLoanType.vue'
import ListLoanSub from './components/ListLoanSub.vue'
import FormLoanSub from './components/FormLoanSub.vue'
import pouchDbMixin from '../../../mixins/pouchDbMixin'

export default {
  name: 'ManageLoanType',
  components: {
    ListLoanType,
    FormLoanType,
    ListLoanSub,
    FormLoanSub,
  },
  mixins: [pouchDbMixin],
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      isDark,
      search,
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiChevronDown,
      },
    }
  },
  data() {
    return {
      loan: 'Submission',
      showCreateLoanTypeModal: false,
      showCreateLoanSubModal: false,
      messageAlert: null,
      loanTypes: [],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    projects() {
      return this.$store.getters['manageProject/getProjects']
    },
    project() {
      return this.$store.getters['manageSalary/getCurrentProject']
    },
  },
  async mounted() {
    await this.$store.dispatch('manageProject/getProjects', { sub_id: this.user.sub_id })
    if (this.projects) {
      if (!this.project || !Object.keys(this.project).length) {
        this.$store.commit('manageSalary/setCurrentProject', this.projects[0])
      }
    }
    this.$root.$on('closeCreateSubModal', () => {
      this.showCreateLoanSubModal = false
    })
    this.$root.$on('closeCreateTypeModal', () => {
      this.showCreateLoanTypeModal = false
    })

    // to fetch list of approvers
    await this.$store.dispatch('auth/fetchListUser', {
      sub_id: this.user.sub_id,
    })
  },
  methods: {
    openFormLoan(type) {
      if (this.project && Object.keys(this.project).length) {
        if (type === 'type') {
          this.showCreateLoanTypeModal = true
        }
        if (type === 'sub') {
          this.showCreateLoanSubModal = true
        }
      } else {
        this.messageAlert = 'Please select a project first'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}

.v-btn.select-project {
  padding: 0;
  border: none;
  font-size: 18px;
  font-weight: bold;
  height: 48px !important;

  .logo-image {
    border: 1px solid lightgrey;
    border-radius: 3px;
    margin-right: 1rem;
  }

  span.v-ripple__container {
    opacity: 0 !important;
  }
}

.select-project:before {
  opacity: 0 !important;
}
</style>
