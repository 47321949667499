var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.loanTypes,"item-key":"id","show-select":"","custom-filter":_vm.filterLoanTypeByName},scopedSlots:_vm._u([{key:"item.loanName",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"no-gutters":""}},[_c('div',{style:({ color: _vm.isDark ? 'white' : '9E9E9E' })},[_vm._v(" "+_vm._s(item.loanName)+" ")])]):_vm._e()],1):_vm._e()]}},{key:"item.approver",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{style:({ color: _vm.isDark ? 'white' : 'black' })},[_vm._v(" "+_vm._s(item.formatttedApprover)+" ")])],1):_vm._e()]}},{key:"item.termsOfPayment",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(item.termsOfPayment === null || "" ? "--" : item.termsOfPayment + " hari")+" ")])],1):_vm._e()]}},{key:"item.interest",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(item.interest === null || "" ? "--" : Number(item.interest)*100 + "%" + " atau " + item.interest)+" ")])],1):_vm._e()]}},{key:"item.maxLoan",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{style:({ color: _vm.isDark ? 'white' : 'black' })},[_vm._v(" "+_vm._s(item.maxLoan === "" ? "--" : _vm.formatToIDRCurrency(item.maxLoan))+" ")])],1):_vm._e()]}},{key:"item.billingDue",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{style:({ color: _vm.isDark ? 'white' : 'black' })},[_vm._v(" "+_vm._s(item.billingDue ? item.billingDue : 'at Maturity')+" ")])],1):_vm._e()]}},{key:"item.billingPeriod",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{style:({ color: _vm.isDark ? 'white' : 'black' })},[_vm._v(" "+_vm._s(item.billingPeriod ? item.billingPeriod : 'at Maturity')+" ")])],1):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{staticClass:"action-btn me-4",style:(_vm.isDark
              ? { 'border-color': '#5E5669AD' }
              : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),(item.loanName.split(' ')[0] !== 'Cicilan')?_c('v-btn',{staticClass:"action-btn",style:(_vm.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1):_vm._e()],1)],1):_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsHorizontalCircle))])],1)]}}],null,true)},[_c('v-list',{attrs:{"outlined":""}},[_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiAccountGroup))])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('ConfirmDeleteModal',{attrs:{"item-id":_vm.itemId,"loading":_vm.deleteLoading},on:{"handle-delete":_vm.deleteLoanType},model:{value:(_vm.showDeleteConfirmationModal),callback:function ($$v) {_vm.showDeleteConfirmationModal=$$v},expression:"showDeleteConfirmationModal"}},[_vm._v(" Are you sure you want to delete this loan type? ")]),_c('FormLoanType',{attrs:{"edit-data":_vm.editData},model:{value:(_vm.showEditLoanTypeModal),callback:function ($$v) {_vm.showEditLoanTypeModal=$$v},expression:"showEditLoanTypeModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }