var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.loanSubs,"search":_vm.search,"item-key":"id","show-select":"","custom-filter":_vm.filterName},scopedSlots:_vm._u([{key:"item.recipient",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"no-gutters":""}},[_c('div',[_vm._v(" "+_vm._s(!item.recipient.name || item.recipient.name === "" ? "--" : item.recipient.name)+" ")]),_c('div',{style:({ color: _vm.isDark ? 'white' : 'black' })},[_vm._v(" "+_vm._s(!item.recipient.phone_number || item.recipient.phone_number === "" ? "--" : item.recipient.phone_number)+" ")])]):_vm._e()]}},{key:"item.typeTotal",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"no-gutters":""}},[(item.loanTypeId && item.loanTypeId.loanName)?_c('div',[_vm._v(" "+_vm._s(item.loanTypeId.loanName === "" ? "--" : item.loanTypeId.loanName)+" ")]):_vm._e(),_c('div',{style:({ color: _vm.isDark ? 'white' : 'black' })},[_vm._v(" "+_vm._s(item.totalLoan === "" ? "--" : _vm.formatToIDRCurrency(item.totalLoan))+" ")])]):_vm._e()]}},{key:"item.payedLoanBase",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(item.payedLoanBase === "" ? "--" : _vm.formatToIDRCurrency(item.payedLoanBase))+" ")])],1):_vm._e()]}},{key:"item.remainLoanBase",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{style:({ color: _vm.isDark ? 'white' : 'black' })},[_vm._v(" "+_vm._s(item.remainLoanBase === "" ? "--" : _vm.formatToIDRCurrency(item.remainLoanBase))+" ")])],1):_vm._e()]}},{key:"item.approvalStatus",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(typeof item.approvalStatus === 'string')?_c('div',[_c('strong',[_vm._v(" "+_vm._s(item.approvalStatus)+" ")])]):_vm._e(),(typeof item.approvalStatus === 'object')?_c('strong',{style:(item.approvalStatus ? item.approvalStatus : '')},[_vm._v(" "+_vm._s(item.approvalStatus ||"--")+" ")]):_vm._e()])],1):_vm._e()]}},{key:"item.billingStatus",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[(item.billingStatus === 'PENDING')?_c('v-col',{staticClass:"red--text"},[_c('div',{staticStyle:{"color":"yellow"}},[_vm._v(" "+_vm._s(item.billingStatus)+" ")])]):_vm._e(),(item.billingStatus === 'PAID')?_c('v-col',{staticClass:"red--text"},[_c('div',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(item.billingStatus)+" ")])]):_vm._e(),(item.billingStatus === 'EXPIRED')?_c('v-col',[_c('div',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.billingStatus)+" ")])]):_vm._e(),(item.billingStatus === 'LATE')?_c('v-col',[_c('div',{staticStyle:{"color":"blue"}},[_vm._v(" "+_vm._s(item.billingStatus)+" ")])]):_vm._e()],1):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{style:({ color: _vm.isDark ? 'white' : 'black' })},[_vm._v(" "+_vm._s(_vm.$moment(new Date(item.createdAt)).format('DD MMM YYYY HH:mm'))+" ")])],1):_vm._e()]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{style:({ color: _vm.isDark ? 'white' : 'black' })},[_vm._v(" "+_vm._s(item.dueDate ? _vm.$moment(new Date(item.dueDate)).format('DD MMM YYYY HH:mm') : "--")+" ")])],1):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{staticClass:"action-btn me-4 text-capitalize",style:(_vm.isDark
              ? { 'border-color': '#5E5669AD' }
              : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.toBillingPage(item.id)}}},[_vm._v(" View detail ")]),(item.approvalStatus !== 'Approved')?_c('v-btn',{staticClass:"action-btn me-4",style:(_vm.isDark
              ? { 'border-color': '#5E5669AD' }
              : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1):_vm._e(),(item.approvalStatus === 'Need Approval')?_c('v-btn',{staticClass:"action-btn",style:(_vm.isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1):_vm._e()],1)],1):_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true,"close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsHorizontalCircle))])],1)]}}],null,true)},[_c('v-list',{attrs:{"outlined":""}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1),(item.approvalStatus === 'Need Approval')?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1):_vm._e(),_c('v-btn',{staticClass:"action-btn me-4 text-capitalize",style:(_vm.isDark
              ? { 'border-color': '#5E5669AD' }
              : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.toBillingPage(item.id)}}},[_vm._v(" View detail ")])],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('ConfirmDeleteModal',{attrs:{"item-id":_vm.itemId,"loading":_vm.deleteLoading},on:{"handle-delete":_vm.deleteLoanSub},model:{value:(_vm.showDeleteConfirmationModal),callback:function ($$v) {_vm.showDeleteConfirmationModal=$$v},expression:"showDeleteConfirmationModal"}},[_vm._v(" Are you sure you want to delete this loan submission? ")]),_c('FormLoanSub',{attrs:{"edit-data":_vm.editData,"loan-types":_vm.loanTypes},model:{value:(_vm.showEditLoanSubModal),callback:function ($$v) {_vm.showEditLoanSubModal=$$v},expression:"showEditLoanSubModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }