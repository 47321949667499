<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="620px"
  >
    <!-- max-width="1000px" for survey creator -->
    <v-form
      ref="form"
      @submit.prevent="createLoanType"
    >
      <v-card>
        <v-card-title>
          <v-row align="center">
            <v-col cols="1">
              <v-btn
                icon
                @click="dialog = false"
              >
                <v-icon size="20">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-col>

            <v-col align="center">
              <h5>
                {{ !editData.id || editData.id === '' ? 'Create ' : 'Edit ' }}
                Loan Type
              </h5>
            </v-col>

            <v-col cols="1"></v-col>
          </v-row>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-row
            class="mt-3 px-3"
          >
            <v-text-field
              v-model="loanType.loanName"
              label="Loan Name"
              outlined
              :rules="nameRules"
            >
            </v-text-field>
          </v-row>

          <v-row
            class="
              px-3"
            justify="space-between"
          >
            <v-select
              v-model="loanType.approver"
              :items="approverList"
              :item-text="({name, email}) => name || email"
              label="Approved by"
              return-object
              outlined
              multiple
              class="mr-2"
            >
              <template v-slot:prepend-item>
                <v-subheader>Select by role</v-subheader>
                <v-form ref="role">
                  <v-checkbox
                    v-for="item in roleList"
                    :id="item.role"
                    :key="item.role"
                    v-model="loanType.approver"
                    :label="item.role"
                    :value="item"
                    class="ml-4 my-2"
                    multiple
                    ripple
                    hide-details
                  >
                  </v-checkbox>
                </v-form>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>


            <v-select
              v-model="loanType.approvalType"
              :items="approvalTypeItems"
              label="Approval type"
              outlined
            ></v-select>
          </v-row>

          <v-row justify="space-between">
            <v-col>
              <v-text-field
                v-model="loanType.maxLoan"
                label="MaxLoan"
                type="number"
                outlined
                :rules="maxLoanRules"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="loanType.termsOfPayment"
                label="Terms Of Payment"
                type="number"
                outlined
                :rules="termsOfPaymentRules"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <div class="mb-n2">
            Interest
          </div>
          <v-row justify="space-between">
            <v-col>
              <v-text-field
                v-model="loanType.interest"
                outlined
                label="Interest in decimal (0.1 = 10%)"
                :rules="interestRules"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="loanType.interestPeriod"
                :items="monthWeek"
                label="Interest Period"
                value="Monthly"
                outlined
                :rules="interestPeriodRules"
              ></v-select>
            </v-col>
          </v-row>

          <v-divider class="pb-6"></v-divider>

          <div class="mb-n2">
            Billing Period
          </div>
          <v-radio-group
            v-model="loanType.billingPeriod"
            row
            :rules="billingPeriodRules"
          >
            <v-radio
              label="Weekly"
              value="Weekly"
              @click="billingPeriod = 'Weekly'"
            ></v-radio>

            <v-radio
              label="Monthly"
              value="Monthly"
              @click="billingPeriod = 'Monthly'"
            ></v-radio>
            <v-radio
              label="at Maturity"
              value="at Maturity"
              @click="billingPeriod = 'at Maturity'"
            ></v-radio>
          </v-radio-group>
          <v-row
            class="mt-2"
          >
            <v-select
              v-if="billingPeriod === 'Monthly'"
              v-model="loanType.billingDue"
              :items="month"
              label="Date"
              style="max-width:100px"
              outlined
            ></v-select>
            <v-select
              v-if="billingPeriod === 'Weekly'"
              v-model="loanType.billingDue"
              style="max-width:100px"
              :items="days"
              label="Day"
              outlined
            ></v-select>
          </v-row>
          <v-row
            justify="space-between"
            align="center"
          >
            <v-col>
              <p>Nett Off</p>
            </v-col>
            <v-col>
              <v-switch
                v-model="loanType.enableNettOff"
                inset
              ></v-switch>
            </v-col>
          </v-row>
          <div style="display: flex; justify-content: center;">
            <v-btn
              text
              color="primary"
              class="text-capitalize font-weight-bold"
              @click="showAdditionalSurvey = !showAdditionalSurvey"
            >
              <span :class="isAdditionalFilled ? 'me-3' : ''">
                Additional Data
              </span>
              <v-icon
                v-if="isAdditionalFilled"
                size="20"
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </v-btn>
          </div>
          <div v-if="showAdditionalSurvey">
            <survey-creator
              :json-input="typeof loanType.additional === 'string' ? JSON.parse(loanType.additional) : typeof loanType.additional === 'object' ? loanType.additional : {}"
              :show-survey-title="isShowSurveyTitle"
              :is-auto-save="true"
              @jsonOutput="surveyJsonOutput"
            ></survey-creator>
          </div>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            type="submit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline, mdiCheck } from '@mdi/js'
import SurveyCreator from '../../../../components/SurveyCreator/SurveyCreator.vue'

export default {
  name: 'FormLoanType',
  components: {
    SurveyCreator,
  },
  setup() {
    return {
      icons: { mdiClose, mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline, mdiCheck },
    }
  },
  props: {
    value: { type: Boolean, required: false },
    editData: {
      type: Object,
      default() {
        return { id: '' }
      },
    },
    project: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      days: ['Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu', 'Minggu'],
      month: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      billingPeriod: '',
      monthWeek: ['Monthly', 'Yearly'],
      approvalTypeItems: ['Approve by min 1 approver', 'Approve by majority approver', 'Approve by all approver'],
      companies: ['Cartenz', 'Realtegic'],
      showAdditionalSurvey: false,
      isAdditionalFilled: false,
      isShowSurveyTitle: false,
      selectedRoles: [],

      // {R U L E S} //
      nameRules: [value => !!value || 'Loan name wajib diisi'],
      maxLoanRules: [value => !!value || 'Max Loan wajib diisi'],
      interestRules: [value => !!value || 'Interest wajib diisi'],
      interestPeriodRules: [value => !!value || 'Interest period wajib diisi'],
      billingPeriodRules: [value => !!value || 'Billing period wajib diisi'],
      termsOfPaymentRules: [value => !!value || 'Terms of payment wajib diisi'],

      /// /////////////
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    loanType() {
      return {
        loanName: this.editData?.loanName,
        approver: this.editData?.approver ? this.editData.approver : '',
        approvalType: this.editData?.approvalType ? this.editData.approvalType : '',
        maxLoan: this.editData?.maxLoan,

        termsOfPayment: this.editData?.termsOfPayment,
        interest: this.editData?.interest ? this.editData.interest : '',
        interestPeriod: this.editData?.interestPeriod ? this.editData.interestPeriod : '',
        billingPeriod: this.editData?.billingPeriod ? this.editData.billingPeriod : '',
        billingDue: this.editData?.billingDue,
        loanStatus: 'Good',
        isFlexibleBilling: true,
        enableNettOff: this.editData?.enableNettOff || false,
        projectId: this.project?.ID,
        additional: this.editData?.additional ? this.editData.additional : {},
      }
    },
    approverItems() {
      return this.$store.getters['auth/getListUsers']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    userList() {
      return this.$store.getters['auth/getListUsers']
    },
    roleList() {
      return this.$store.getters['global/getRoleSettings']
    },
    approverList() {
      return [{ header: 'Select by user' }, ...this.userList]
    },
  },
  watch: {
    editData(data) {
      //console.log('watch editData', data)
      if (!data.additional || (typeof data.additional === 'object' && Object.keys(data.additional).length === 0)) {
        this.isAdditionalFilled = false
        this.showAdditionalSurvey = false
      } else {
        this.isAdditionalFilled = true
        this.showAdditionalSurvey = false
      }
    },
    billingPeriod(data) {
      if (data === 'at Maturity') {
        this.loanType.billingDue = null
      }
    },
  },
  created() {
    //console.log(this.project, 'project')
  },
  methods: {
    async createLoanType() {
      //console.log(this.loanType, '<<< LOAN TYPE')
      this.$refs.form.validate()
      if (!this.editData.editPage) {
        //console.log('masuk create')
        const res = await this.$store.dispatch('manageLoan/createLoanTypes', {
          ...this.loanType,
          sub_id: this.user.sub_id,
        })
        if (res.status) {
          //console.log('create berhasil')
          this.$root.$emit('getLoanTypes')
          this.$root.$emit('closeCreateTypeModal')
        }
      } else {
        // edit loan type
        await this.$store.dispatch('manageLoan/updateLoanTypes', {
          input_data: {
            ...this.loanType,
            id: this.editData.id,
          },
          sub_id: this.user.sub_id,
        })
        this.dialog = false

        await this.$store.dispatch('manageLoan/getLoanTypes', {
          sub_id: this.user.sub_id,
        })
      }
    },
    surveyJsonOutput(value) {
      this.isAdditionalFilled = true

      // this.showAdditionalSurvey = false
      this.loanType.additional = value
      //console.log('surveyjs result', value)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
