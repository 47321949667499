<template>
  <div>
    <!-- Table -->
    <v-data-table
      v-model="selected"
      :search="search"
      :headers="headers"
      :items="loanTypes"
      item-key="id"
      show-select
      class="elevation-1"
      :custom-filter="filterLoanTypeByName"
    >
      <template v-slot:item.loanName="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            no-gutters
          >
            <div :style="{ color: isDark ? 'white' : '9E9E9E' }">
              {{ item.loanName }}
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.approver="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col :style="{ color: isDark ? 'white' : 'black' }">
            <!-- {{ item.formatttedApprover.length ? item.formatttedApprover.slice(0, item.formatttedApprover.length-1) : "--" }} -->
            {{ item.formatttedApprover }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.termsOfPayment="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col>
            {{ item.termsOfPayment === null || "" ? "--" : item.termsOfPayment + " hari" }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.interest="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col>
            {{ item.interest === null || "" ? "--" : Number(item.interest)*100 + "%" + " atau " + item.interest }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.maxLoan="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col :style="{ color: isDark ? 'white' : 'black' }">
            {{ item.maxLoan === "" ? "--" : formatToIDRCurrency(item.maxLoan) }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.billingDue="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col :style="{ color: isDark ? 'white' : 'black' }">
            {{ item.billingDue ? item.billingDue : 'at Maturity' }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.billingPeriod="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col :style="{ color: isDark ? 'white' : 'black' }">
            {{ item.billingPeriod ? item.billingPeriod : 'at Maturity' }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.action="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col>
            <v-btn
              class="action-btn me-4"
              small
              outlined
              :style="isDark
                ? { 'border-color': '#5E5669AD' }
                : { 'border-color': 'lightgray' }"
              color="primary"
              @click="openEdit(item.id)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>
            <v-btn
              v-if="item.loanName.split(' ')[0] !== 'Cicilan'"
              class="action-btn"
              small
              outlined
              :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="primary"
              @click="confirmDeletion(item.id)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-menu
          v-else
          offset-y
          :close-on-content-click="true"
          :close-on-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              icon
              v-bind="attrs"
            >
              <v-icon>{{ icons.mdiDotsHorizontalCircle }}</v-icon>
            </v-btn>
          </template>

          <v-list outlined>
            <v-btn
              icon
              color="primary"
            >
              <v-icon>{{ icons.mdiAccountGroup }}</v-icon>
            </v-btn>

            <v-btn
              icon
              color="primary"
              @click="openEdit(item.id)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>

            <v-btn
              icon
              color="primary"
              @click="confirmDeletion(item.id)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <ConfirmDeleteModal
      v-model="showDeleteConfirmationModal"
      :item-id="itemId"
      :loading="deleteLoading"
      @handle-delete="deleteLoanType"
    >
      Are you sure you want to delete this loan type?
    </ConfirmDeleteModal>
    <FormLoanType
      v-model="showEditLoanTypeModal"
      :edit-data="editData"
    />
  </div>
</template>

<script>
/* eslint-disable */
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import {
  mdiPlus,
  mdiMagnify,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiAccountGroup,
  mdiDotsHorizontalCircle,
} from '@mdi/js'

// import pouchDbMixin from '../../../../mixins/pouchDbMixin'
import FormLoanType from './FormLoanType.vue'
import ConfirmDeleteModal from '../../shared/ConfirmDelete.vue'

export default {
  name: 'ListLoanType',
  components: { FormLoanType, ConfirmDeleteModal },

  props: ['search'],

  // mixins: [pouchDbMixin],
  data() {
    return {
      editData: {},
      selected: [],
      headers: [
        {
          text: 'Loan Name',
          align: 'start',
          value: 'loanName',
        },
        { text: 'Approval', value: 'formattedApprover' },
        { text: 'Interest', value: 'interest' },
        { text: 'Maximum Loan', value: 'maxLoan' },
        { text: 'Terms Of Payment', value: 'termsOfPayment' },
        { text: 'Date Payment', value: 'billingDue' },
        { text: 'Period Payment', value: 'billingPeriod' },
        { text: 'Action', value: 'action' },
      ],
      itemId: null,
      showEditLoanTypeModal: false,
      showCreateEmployeeGroupModal: false,
      showDeleteConfirmationModal: false,
      deleteLoading: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    projects() {
      return this.$store.getters['manageProject/getProjects']
    },
    project() {
      return this.$store.getters['manageSalary/getCurrentProject']
    },
    rawLoanTypes() {
      return this.$store.getters['manageLoan/getLoanTypes']
    },
    loanTypes() {
      const dataLoanType = this.rawLoanTypes.filter(
        loanType => loanType.projectId && +loanType?.projectId === +this?.project?.ID,
      )
      const formattedLoanType = dataLoanType.map(loanType => {
        let containerForByUser = {}
        let containerForByRole = {}

        loanType.approver.forEach(approverType => {
          //by user
          if (approverType?.email) {
            containerForByUser[approverType?.email] = containerForByUser[approverType?.email]++ || 0
          }

          //by role
          if (!approverType?.email) {
            containerForByRole[approverType?.role] = containerForByRole[approverType?.role]++ || 0
          }
        })

        let formattedApprover = ''

        if (Object.keys(containerForByUser).length === 1) formattedApprover += Object.keys(containerForByUser)[0]
        if (Object.keys(containerForByRole).length === 1) formattedApprover += Object.keys(containerForByRole)[0]
        if (Object.keys(containerForByUser).length > 1)
          formattedApprover += `User: ${Object.keys(containerForByUser).length}`
        if (Object.keys(containerForByRole).length > 1)
          formattedApprover += `Role: ${Object.keys(containerForByRole).length}`
        if (Object.keys(containerForByUser).length === 1 && Object.keys(containerForByRole).length === 1)
          formattedApprover = `${Object.keys(containerForByUser)[0]} & ${Object.keys(containerForByRole)[0]}`

        return {
          ...loanType,
          formattedApprover,
        }
      })

      return formattedLoanType
    },
    // FORTESTINGDELETEASKDIO() {
    //   const dataLoanType = this.rawLoanTypes.filter(
    //     loanType => loanType.projectId && +loanType?.projectId === +this?.project?.ID,
    //   )
    //   const formattedLoanType = dataLoanType.map(loanType => {
    //     let containerForByUser = {}
    //     let containerForByRole = {}
    //     loanType.approver.forEach(approverType => {
    //       //by user
    //       if (approverType?.email) {
    //         containerForByUser[approverType?.email] = containerForByUser[approverType?.email]++ || 0
    //       }

    //       //by role
    //       if (!approverType?.email) {
    //         containerForByRole[approverType?.role] = containerForByRole[approverType?.role]++ || 0
    //       }
    //     })

    //     let formattedApprover = ''

    //     if (Object.keys(containerForByUser).length === 1) formattedApprover += Object.keys(containerForByUser)[0]
    //     if (Object.keys(containerForByRole).length === 1) formattedApprover += Object.keys(containerForByRole)[0]
    //     if (Object.keys(containerForByUser).length > 1)
    //       formattedApprover += `Role: ${Object.keys(containerForByUser).length}`
    //     if (Object.keys(containerForByRole).length > 1)
    //       formattedApprover += `Role: ${Object.keys(containerForByRole).length}`
    //     if (Object.keys(containerForByUser).length === 1 && Object.keys(containerForByRole).length === 1)
    //       formattedApprover = `${Object.keys(containerForByUser)[0]} & ${Object.keys(containerForByRole)[0]}`

    //     return {
    //       ...loanType,
    //       formattedApprover,
    //     }
    //   })

    //   return formattedLoanType
    // },
  },
  watch: {},
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
        mdiAccountGroup,
        mdiDotsHorizontalCircle,
      },
    }
  },
  async mounted() {
    this.$root.$on('closeCreateTypeModal', () => {
      this.showCreateLoanTypeModal = false
    })
    await this.getLoanTypes()
  },
  methods: {
    confirmDeletion(itemId) {
      this.itemId = itemId
      this.showDeleteConfirmationModal = true
    },
    async deleteLoanType(id) {
      try {
        await this.$store.dispatch('manageLoan/deleteLoanType', {
          sub_id: this.user.sub_id,
          id,
        })
        this.getLoanTypes()
        this.deleteLoading = false
        this.showDeleteConfirmationModal = false
      } catch (err) {
        console.log(err)
      }
    },
    openEdit(id) {
      this.editData = this.loanTypes.find(el => el.id === id)
      this.editData.editPage = true
      this.showEditLoanTypeModal = true
    },
    async getLoanTypes() {
      try {
        await this.$store.dispatch('manageLoan/getLoanTypes', {
          sub_id: this.user.sub_id,
        })

        // this.loanTypes = res.loanType.filter(loanType => +loanType.projectId === +this.project.ID)
      } catch (err) {
        console.log(err)
      }
    },
    formatToIDRCurrency(number) {
      return number ? new Intl.NumberFormat('id-ID').format(number) : 0
    },
    filterLoanTypeByName(value, search, item) {
      return Object.values(item).some((prop, i) => {
        return prop.toString().toLowerCase().includes(search)
      })
    },
  },
}
</script>

<style>
</style>
