<template>
  <div id="surveyCreatorContainer"></div>
</template>

<script>
import { SurveyCreator } from 'survey-creator-knockout'

import * as Survey from 'survey-core'
import * as widgets from 'surveyjs-widgets'
import { init as customWidget } from './customwidget'

// import "survey-core/defaultV2.css"; // sumber error
import 'survey-creator-core/survey-creator-core.css'

// widgets.icheck(Survey);
widgets.select2(Survey)
widgets.inputmask(Survey)
widgets.jquerybarrating(Survey)
widgets.jqueryuidatepicker(Survey)
widgets.nouislider(Survey)
widgets.select2tagbox(Survey)
widgets.sortablejs(Survey)
widgets.ckeditor(Survey)
widgets.autocomplete(Survey)
widgets.bootstrapslider(Survey)
customWidget(Survey)

export default {
  name: 'survey-creator',
  props: {
    jsonInput: {
      type: Object,
      default() {
        return {}
      },
    },
    showSurveyTitle: {
      type: Boolean,
      default() {
        return true
      },
    },
    isAutoSave: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  mounted() {
    const self = this
    const options = { showLogicTab: true, haveCommercialLicense: true }
    this.creator = new SurveyCreator(options)
    this.creator.JSON = this.jsonInput
    this.creator.showSurveyTitle = this.showSurveyTitle
    this.creator.isAutoSave = this.isAutoSave
    this.creator.saveSurveyFunc = function () {
      const result = JSON.parse(this.text)
      // //console.log(result)
      self.result(result)
    }
    this.creator.render('surveyCreatorContainer')
  },
  methods: {
    result(data) {
      this.$emit('jsonOutput', data)
    },
  },
}
</script>

<style>
#surveyCreatorContainer {
  height: calc(100vh - 125px);
  width: 100%;
}
</style>
