<template>
  <!-- Table -->
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="loanSubs"
      :search="search"
      item-key="id"
      show-select
      class="elevation-1"
      :custom-filter="filterName"
    >
      <template v-slot:item.recipient="{item}">
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <div>
            {{ !item.recipient.name || item.recipient.name === "" ? "--" : item.recipient.name }}
          </div>
          <div :style="{ color: isDark ? 'white' : 'black' }">
            {{ !item.recipient.phone_number || item.recipient.phone_number === "" ? "--" : item.recipient.phone_number }}
          </div>
        </v-col>
      </template>
      <template v-slot:item.typeTotal="{item}">
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <div v-if="item.loanTypeId && item.loanTypeId.loanName">
            {{ item.loanTypeId.loanName === "" ? "--" : item.loanTypeId.loanName }}
          </div>
          <div :style="{ color: isDark ? 'white' : 'black' }">
            {{ item.totalLoan === "" ? "--" : formatToIDRCurrency(item.totalLoan) }}
          </div>
        </v-col>
      </template>
      <template v-slot:item.payedLoanBase="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col>
            {{ item.payedLoanBase === "" ? "--" : formatToIDRCurrency(item.payedLoanBase) }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.remainLoanBase="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col :style="{ color: isDark ? 'white' : 'black' }">
            {{ item.remainLoanBase === "" ? "--" : formatToIDRCurrency(item.remainLoanBase) }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.approvalStatus="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col>
            <div v-if="typeof item.approvalStatus === 'string'">
              <strong>
                {{ item.approvalStatus }}
              </strong>
            </div>
            <strong
              v-if="typeof item.approvalStatus === 'object'"
              :style="item.approvalStatus ? item.approvalStatus : ''"
            >
              {{ item.approvalStatus ||"--" }}
            </strong>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.billingStatus="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col
            v-if="item.billingStatus === 'PENDING'"
            class="red--text"
          >
            <div style="color: yellow">
              {{ item.billingStatus }}
            </div>
          </v-col>
          <v-col
            v-if="item.billingStatus === 'PAID'"
            class="red--text"
          >
            <div style="color: green">
              {{ item.billingStatus }}
            </div>
          </v-col>
          <v-col v-if="item.billingStatus === 'EXPIRED'">
            <div style="color: red">
              {{ item.billingStatus }}
            </div>
          </v-col>
          <v-col v-if="item.billingStatus === 'LATE'">
            <div style="color: blue">
              {{ item.billingStatus }}
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.createdAt="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col :style="{ color: isDark ? 'white' : 'black' }">
            {{ $moment(new Date(item.createdAt)).format('DD MMM YYYY HH:mm') }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.dueDate="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col :style="{ color: isDark ? 'white' : 'black' }">
            {{ item.dueDate ? $moment(new Date(item.dueDate)).format('DD MMM YYYY HH:mm') : "--" }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.action="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-col>
            <v-btn
              class="action-btn me-4 text-capitalize"
              small
              outlined
              :style="isDark
                ? { 'border-color': '#5E5669AD' }
                : { 'border-color': 'lightgray' }"
              color="primary"
              @click="toBillingPage(item.id)"
            >
              View detail
            </v-btn>
            <v-btn
              v-if="item.approvalStatus !== 'Approved'"
              class="action-btn me-4"
              small
              outlined
              :style="isDark
                ? { 'border-color': '#5E5669AD' }
                : { 'border-color': 'lightgray' }"
              color="primary"
              @click="openEdit(item.id)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>
            <v-btn
              v-if="item.approvalStatus === 'Need Approval'"
              class="action-btn"
              small
              outlined
              :style="isDark ? { 'border-color': '#5E5669AD' } : { 'border-color': 'lightgray' }"
              color="primary"
              @click="confirmDeletion(item.id)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-menu
          v-else
          offset-y
          :close-on-content-click="true"
          :close-on-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsHorizontalCircle }}</v-icon>
            </v-btn>
          </template>

          <v-list outlined>
            <v-btn
              icon
              color="primary"
              @click="openEdit(item.id)"
            >
              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
            </v-btn>

            <v-btn
              v-if="item.approvalStatus === 'Need Approval'"
              icon
              color="primary"
              @click="confirmDeletion(item.id)"
            >
              <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
            </v-btn>
            <v-btn
              class="action-btn me-4 text-capitalize"
              small
              outlined
              color="primary"
              :style="isDark
                ? { 'border-color': '#5E5669AD' }
                : { 'border-color': 'lightgray' }"
              @click="toBillingPage(item.id)"
            >
              View detail
            </v-btn>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <ConfirmDeleteModal
      v-model="showDeleteConfirmationModal"
      :item-id="itemId"
      :loading="deleteLoading"
      @handle-delete="deleteLoanSub"
    >
      Are you sure you want to delete this loan submission?
    </ConfirmDeleteModal>
    <FormLoanSub
      v-model="showEditLoanSubModal"
      :edit-data="editData"
      :loan-types="loanTypes"
    />
  </div>
</template>

<script>
/* eslint-disable */
import useAppConfig from '@core/@app-config/useAppConfig'
import {
  mdiPlus,
  mdiMagnify,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiAccountGroup,
  mdiDotsHorizontalCircle,
} from '@mdi/js'
import FormLoanSub from './FormLoanSub.vue'
import ConfirmDeleteModal from '../../shared/ConfirmDelete.vue'

export default {
  name: 'ListLoanSub',
  components: { FormLoanSub, ConfirmDeleteModal },

  props: ['search'],
  data() {
    return {
      showEditLoanSubModal: false,
      editData: {},
      selected: [],
      headers: [
        { text: 'Description', value: 'billingDescription' },
        { text: "Recipient's name", value: 'recipient' },
        { text: 'Type & Total loan', value: 'typeTotal' },
        { text: 'Total paid', value: 'payedLoanBase' },
        { text: 'Remaining loan', value: 'remainLoanBase' },
        { text: 'Approval status', value: 'approvalStatus' },
        { text: 'Billing status', value: 'billingStatus' },
        { text: 'Created time', value: 'createdAt' },
        { text: 'Due date & time', value: 'dueDate' },
        { text: 'Action', value: 'action' },
      ],
      custList: [],
      allContact: [],
      filterAllContact: '',
      itemId: null,
      showDeleteConfirmationModal: false,
      deleteLoading: false,
      // search: '',
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    projects() {
      return this.$store.getters['manageProject/getProjects']
    },
    project() {
      return this.$store.getters['manageSalary/getCurrentProject']
    },
    rawLoanSubs() {
      return this.$store.getters['manageLoan/getLoanSubs'] || []
    },
    loanSubs() {
      return this.rawLoanSubs.filter(loanSub => +loanSub?.loanTypeId?.projectId === +this.project?.ID)
    },
    rawLoanTypes() {
      return this.$store.getters['manageLoan/getLoanTypes']
    },
    loanTypes() {
      return this.rawLoanTypes.filter(loanType => +loanType?.projectId === +this.project?.ID)
    },
  },
  watch: {
    loanSubsProps(data) {
      //console.log('watch loanSubs', data)
    },
    loanTypes(data) {
      //console.log('watch loanTypes', data)
    },
  },
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
        mdiAccountGroup,
        mdiDotsHorizontalCircle,
      },
    }
  },
  async mounted() {
    await this.getLoanSubmissions()
    await this.getLoanTypes()
    await this.loadCustomerListGroup()
  },
  methods: {
    confirmDeletion(itemId) {
      this.itemId = itemId
      this.showDeleteConfirmationModal = true
    },
    async loadCustomerListGroup() {
      // Untuk fetch cust list & group
      const res = await this.$store.dispatch('global/getContactLists', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })
      if (res.status) {
        this.custList = res.data
      }
      this.allContact = []
      for (var list of this.custList) {
        this.allContact = this.allContact.concat(
          list.contacts.map(x => {
            return {
              ...x,
              list_name: list.list_name,
              list_id: list.list_id,
              label_color: list.label_color,
            }
          }),
        )
      }

      // remove duplicate by room id
      if (this.allContact.length < 5000) {
        this.allContact = this.allContact.filter(
          (room, index, that) =>
            index ===
            that.findIndex(t => t !== undefined && room !== undefined && t.phone_number === room.phone_number),
        )
      }
      this.$store.commit('global/SET_CUSTOMER_LIST_CONTACTS', this.allContact)
      this.allContact = this.allContact.filter(x => {
        let isFiltered = false

        if (this.filterAllContact !== '') {
          for (const field in x) {
            if (x[field] && x[field].toString().toLowerCase().includes(this.filterAllContact.toLowerCase())) {
              isFiltered = true
            }
          }
        } else {
          isFiltered = true
        }

        // //console.log(isFiltered, 666)
        return isFiltered
      })
    },
    async deleteLoanSub(id) {
      await this.$store.dispatch('manageLoan/deleteLoanSubmissions', {
        sub_id: this.user.sub_id,
        id,
      })
      await this.getLoanSubmissions()
      this.deleteLoading = false
      this.showDeleteConfirmationModal = false
    },
    openEdit(id) {
      //console.log(this.loanSubs, 'loanSubs')
      this.editData = this.loanSubs.find(el => el.id === id)
      this.editData.editPage = true
      this.showEditLoanSubModal = true
    },
    async getLoanSubmissions() {
      try {
        await this.$store.dispatch('manageLoan/getLoanSubmissions', {
          sub_id: this.user.sub_id,
        })
      } catch (err) {
        console.log(err)
      }
    },
    formatToIDRCurrency(number) {
      return number ? new Intl.NumberFormat('id-ID').format(number) : 0
    },
    toBillingPage(loanSubId) {
      this.$router.push({ path: `/manage-loan/manage-billing/${loanSubId}` })
    },
    async getLoanTypes() {
      try {
        await this.$store.dispatch('manageLoan/getLoanTypes', {
          sub_id: this.user.sub_id,
        })
      } catch (err) {
        console.log(err)
      }
    },
    formatDate(date) {
      return this.$moment(new Date(date - new Date().getTimezoneOffset() * 60 * 1000)).format('DD MMM YYYY HH:mm')
    },
    filterName(value, search, item) {
      return Object.values(item).some((prop, i) => {
        if (prop && prop.name) {
          //console.log(i, prop)
          //console.log(prop.name.toString().toLowerCase().includes(search))
          return prop.name.toString().toLowerCase().includes(search)
        }
      })
    },
  },
}
</script>

<style>
</style>
